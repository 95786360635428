<template>
  <div
    :class="{ 'align-center': !isPopup }"
    class="wrapper d-flex align-top"
    ref="wrapper"
  >
    <v-menu
      :open-on-hover="computedOpenOnHover"
      :attach="computedAttach"
      :close-on-click="false"
      :close-on-content-click="false"
      :disabled="blockPopup"
      :left="!right && (left || displayToLeft)"
      :nudge-bottom="nudgeBottom"
      :nudge-left="nudgeLeft"
      :nudge-right="nudgeRight"
      :nudge-top="nudgeTop"
      :offset-x="offsetX"
      :offset-y="offsetY"
      :right="right"
      :top="displayToTop"
      :z-index="this.displayZIndex || zIndex"
      min-width="max-content"
      origin="right top"
      ref="menu"
      v-click-outside="clickOutsideArgs"
      v-if="avatarProps_.visible"
      v-model="isOpen"
    >
      <template v-slot:activator="{ on }">
        <v-avatar
          :class="{clickable: !blockPopup, 'mr-2': !hideFullname}"
          v-on="on"
          :rounded="isAccountContact"
          :size="avatarSize()"
          :color="avatarColor(fullname_)"
        >
          <span
            v-if="!contactCanBeAdded"
            :style="initialsStyle()"
          >{{initials}}</span>
          <span
            v-else
            class="align-center"
          >
            <v-icon
              color="white"
              small
            >{{$icon('i.Roles')}}</v-icon>
          </span>
        </v-avatar>
      </template>
      <slot name="popup">
        <v-sheet
          class="ma-0 pa-2 background-plain"
          ref="content"
        >
          <user-ref
            class="user-popup"
            :id="id"
            :avatar-props="{large: true}"
            :document-type="cacheType"
            block-popup
            :hide-phone=false
            :fullname="fullname"
            :item="item"
            :email="email"
            :hide-email="hideEmail"
            is-popup
          />
        </v-sheet>
      </slot>
    </v-menu>
    <div class="d-flex flex-column">
      <div
        class="d-flex align-end preserve-spaces"
        v-if="!hideFullname"
        :class="{'is-for-selection-title' : isForSelection, 'mb-1': isForSelection, 'fullname': isPopup}"
      >
        <span v-if="title && (isForSelection || isPopup)">{{title}} </span>
        <span class="nowrap text-truncate max-width-text">{{fullname_}}</span>
        <v-spacer />
        <v-btn
          v-if="isPopup && userId"
          icon
          small
          @click.stop="navigateTo()"
        >
          <icon-tooltip
            :icon-name="'i.GoToDocument'"
            :tooltip-text="$t('t.GoToDocument')"
            x-small
          />
        </v-btn>
      </div>
      <div class="d-flex">
        <div
          class="detail nowrap d-flex flex-row align-center"
          :class="{'is-for-selection' : isForSelection}"
          v-if="email_ && !hideEmail && (isForSelection || isPopup) && !dense"
        >
          <v-icon
            x-small
            v-if="isPopup"
            class="mr-1"
          >{{$icon('i.Email')}}</v-icon>
          <span class="nowrap text-truncate max-width-text mr-2">{{email_}}</span>
          <v-btn
            class="ml-1"
            v-if="isPopup"
            icon
            x-small
            @click.stop="mailToClipboard"
          >
            <v-icon x-small>{{$icon('i.Copy')}}</v-icon>
          </v-btn>
        </div>
        <span
          class="d-inline-flex preserve-spaces detail"
          v-if="isForSelection"
        >
          <v-icon x-small>{{$icon('i.Cultures')}} </v-icon>
          <document-name-ref
            :id="culture"
            :cache-type="cultureCacheType"
          />
        </span>
      </div>
      <div
        v-if="(groupId || contactGroupId || jobTitle) && (!hideGroup || isForSelection) && !dense"
        class="d-flex nowrap detail"
        :class="{'is-for-selection' : isForSelection}"
      >
        <document-name-ref
          v-if="!isAccountContact"
          :id="groupId"
          :cache-type="groupCacheType"
        />
        <document-name-ref
          v-else-if="contactGroupId"
          :id="contactGroupId"
          :cache-type="contactGroupCacheType"
        />
        <template v-if="jobTitle">
          <template v-if="contactGroupId || groupId"><span class="mx-1"> - </span></template>
          {{jobTitle}}
        </template>
      </div>

      <span
        class="d-inline-flex preserve-spaces detail"
        v-if="isPopup"
      >
        <v-icon
          x-small
          class="mr-1"
        >{{$icon('i.Cultures')}}</v-icon>
        <document-name-ref
          :id="culture"
          :cache-type="cultureCacheType"
        />
      </span>

      <div
        :class="{'is-for-selection' : isForSelection}"
        class="detail d-flex flex-row ml-n1"
        v-if="(isPopup || isForSelection) && (phone1_ || phone2_) && !hidePhone && !dense"
      >
        <phone-btn
          :btn-disabled="isForSelection"
          v-if="phone1_"
          class="d-inline mr-2"
          small
          :phone-number="phone1_"
        />
        <phone-btn
          :btn-disabled="isForSelection"
          v-if="phone2_"
          class="d-inline"
          small
          :phone-number="phone2_"
        />
      </div>
    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'
import navigation from '@/navigationController'

export default {
  inheritAttrs: false,
  components: {
    PhoneBtn: () => import('@/components/phone-btn'),
    UserRef: () => import('@/components/documents-ref/user-ref'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    computedOpenOnHover () {
      return this.openOnHover && !this.isForSelection
    },
    cacheType () {
      return this.documentType
    },
    clickOutsideArgs () {
      return {
        handler: () => {
          this.$set(this, 'isOpen', false)
        },
        include: () => [
          this.$el,
          ...this.getChildrenEl()
        ].filter(e => e?.isConnected)
      }
    },
    groupId () {
      return this.cache?.doc?.groupId
    },
    computedAttach () {
      return this.attach ?? this.attachEl
    },
    contactGroupId () {
      return this.item?.extra?.contactGroupId
    },
    userId () {
      return this.cache?.doc?.userId
    },
    jobTitle () {
      return this.cache?.doc?.jobTitle
    },
    culture () {
      return this.cache?.doc?.culture
    },
    phone1_ () {
      return this.cache?.doc?.phone1
    },
    phone2_ () {
      return this.cache?.doc?.phone2
    },
    fullname_ () {
      return (this.cache?.doc?.fullname?.length ? this.cache?.doc?.fullname : this.cache?.doc?.email) ?? this.fullname ?? this.email_ ?? this.$t('t.None')
    },
    email_ () {
      return this.cache?.doc?.email ?? this.email
    },
    isAccountContact () {
      return this.cache?.doc?.isAccountContact || !this.id
    },
    contactCanBeAdded () {
      return !this.userId && !this.$store.getters.currentUser?.settings?.emailsSystem.some(p => p === this.email_)
    },
    initials () {
      return this.fullname_?.split(' ', 2).map(c => c.charAt(0)).join('').toUpperCase()
    },
    textSize () {
      return {
        'font-size': '10px'
      }
    },
    title () {
      return this.cache?.doc?.title
    },
    avatarProps_ () {
      const _ = Object.assign({
        visible: true,
        xsmall: false,
        // small: true << default
        normal: false,
        large: false,
        xlarge: false,
        saturation: 60,
        lightness: 75
      }, this.avatarProps)

      return _
    }
  },
  mounted () {
    // Close popup with 'esc' key
    document.addEventListener('keydown', this.listenerHandler)
  },
  beforeDestroy () {
    // Remove close popup with 'esc' key
    document.removeEventListener('keydown', this.listenerHandler)
  },
  methods: {
    listenerHandler (e) {
      if (e.key === 'Escape') {
        this.closePopUp()
      }
    },
    mailToClipboard () {
      navigator.clipboard.writeText(this.email_)
    },
    openPopUp () {
      this.$set(this, 'isOpen', false)

      // this.isOpen = false
      this.$nextTick(() => {
        this.$set(this, 'isOpen', true)
      })
    },
    avatarSize (forceValue) {
      if (forceValue) {
        return forceValue
      }
      return this.avatarProps_.xsmall
        ? 22
        : this.avatarProps_.normal
          ? 44
          : this.avatarProps_.large
            ? 52
            : this.avatarProps_.xlarge
              ? 62
              : 32 // default small
    },
    closePopUp () {
      // this.isOpen = false
      this.$set(this, 'isOpen', false)
    },
    getChildrenEl (v = this) {
      return this.lodash.flattenDeep(v?.$children?.map(c => [c.$el, this.getChildrenEl(c)]))
    },
    initialsStyle (forceValue) {
      if (forceValue) {
        return {
          'font-size': forceValue,
          color: 'black'
        }
      }
      return {
        'font-size': this.avatarProps_.xsmall
          ? '0.65em'
          : this.avatarProps_.small
            ? '0.85em'
            : this.avatarProps_.large
              ? '1.15em'
              : this.avatarProps_.xlarge
                ? '1.3em'
                : '1em',
        color: 'black'
      }
    },
    avatarColor (str = '', s = this.avatarProps_.saturation, l = this.avatarProps_.lightness) {
      if (this.contactCanBeAdded) {
        return 'secondary'
      }
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      return 'hsl(' + hash % 360 + ', ' + s + '%, ' + l + '%)'
    },
    navigateTo () {
      navigation.navigateTo(this.id, this.isAccountContact ? 'account-contacts' : 'users')
    }
  },
  data () {
    return {
      contactGroupCacheType: ClientCache.CacheType.ContactGroupRef,
      cultureCacheType: ClientCache.CacheType.CultureRef,
      isOpen: false,
      groupCacheType: ClientCache.CacheType.GroupRef
    }
  },
  mixins: [ClientCache, DisplayableInContainer],
  props: {
    documentType: {
      type: Number,
      default: ClientCache.CacheType.UserRef
    },
    id: String,
    fullname: String,
    email: String,
    phone: String,
    avatarProps: Object,
    openOnHover: {
      type: Boolean,
      default: true
    },
    hideFullname: {
      type: Boolean,
      default: false
    },
    hideEmail: {
      type: Boolean,
      default: false
    },
    hidePhone: {
      type: Boolean,
      default: false
    },
    hideGroup: {
      type: Boolean,
      default: false
    },
    blockPopup: Boolean,
    left: Boolean,
    right: Boolean,
    offsetX: Boolean,
    offsetY: Boolean,
    nudgeBottom: [Number, String],
    nudgeLeft: [Number, String],
    nudgeRight: [Number, String],
    nudgeTop: [Number, String],
    attach: {
      type: [String, Object, HTMLElement, Boolean],
      default: undefined
    },
    dense: Boolean,
    zIndex: {
      type: [Number, String],
      default: 2
    },
    item: Object,
    isForSelection: Boolean,
    isPopup: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.fullname
  font-size 1rem !important

.detail
  font-size 12.72px !important
  opacity 0.65

.is-for-selection
  font-size 12px !important

.is-for-selection-title
  font-size 0.875rem !important

.wrapper
  position relative

.max-width-text
  max-width 250px

.preserve-spaces
  white-space-collapse break-spaces

.user-popup
  z-index 1800 !important
</style>

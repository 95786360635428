import tabController from '@/tabController'
import VueInstance from '@/main'
import recentsController from '@/recentsController'
import sidePanelController from '@/sidePanelController'

export default {
  async downloadFile (file, params) {
    const token = (await VueInstance.$http().get('/tempToken')).data.token
    const path = !params ? `file/${file.id}` : 'importAttachment'
    const url = new URL(`${VueInstance.$http().baseURL()}/${path}`, window.location)
    const urlParams = new URLSearchParams(url.search)
    urlParams.append('token', token)
    if (params) {
      urlParams.append('filename', file.name)
      urlParams.append('dataSourceFolder', params.dataSourceFolder)
    }
    window.open(`${url.href}?${urlParams.toString()}`, '_blank')
  },
  async navigateTo (id, type, params, isReplace) {
    if (type === 'runs') {
      await VueInstance.$http().get('/core/run/' + id).then(r => {
        const url = r.data.url
        if (url) {
          return VueInstance.$router[isReplace ? 'replace' : 'push'](r.data.url).catch(() => { })
        }
      }).catch(v => {
        if (v.response.status === 404) {
          VueInstance.$store.dispatch('showErrorSnackbar', v.response.data.message)
        }
      })
      return
    }

    if (type === 'topics' || type === 'columns' || type === 'column-sets' || type === 'sort-sets') {
      return sidePanelController.displayDocument(id, type, params)
    }

    const currentAccountId = VueInstance.$route.name === 'account' ? VueInstance.$route.params.id : null

    try {
      const r = await VueInstance.$http().post('core/v6/nav', { doc: { id, type }, currentAccountId })
      const accountId = r.data.accountId
      if (type === 'user-accounts') {
        type = 'account-contacts'
        id = id[0]
      }
      if (type === 'dispute-invoices') {
        type = 'disputes'
        id = id[0]
      }
      if (type === 'promise-invoices') {
        type = 'promises'
        id = id[0]
      }
      if (type === 'unapplied-cashes' || type === 'work-in-progress-items') {
        if (!currentAccountId) {
          return recentsController.goToStaticTab({
            documentType: 'accounts',
            documentId: accountId
          }, 'infos')
        } else {
          return
        }
      }

      if (type === 'work-items') {
        return recentsController.goToStaticTab({
          documentType: 'accounts',
          documentId: accountId
        }, 'to-do')
      }

      const scope = accountId ? { documentType: 'accounts', documentId: accountId } : {}

      const tabDocument = tabController.getTabFromDocumentType(type)

      if (tabDocument) {
        if (accountId) {
          const tab = tabController.create(tabDocument, { documentId: id })
          recentsController.addTab(tab, scope, isReplace)
        } else {
          recentsController.goToStaticTab({ documentId: id, documentType: type }, type)
        }
      } else {
        if (isReplace === true) {
          VueInstance.$router.replace('/' + type + '/' + id).catch(() => { })
        } else {
          VueInstance.$router.push('/' + type + '/' + id).catch(() => { })
        }
      }
    } catch (e) {
      return e
    }
  }
}
